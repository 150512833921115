import React from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';

import { NotificationContext } from '../../../components/Notifier/Notifier';

import config from '../../../config/config';
import './Contact.css';

class Contact extends React.Component {
    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        this.state = {
            contentLoaded: false,
            headerTitle: '',
            headerText: '',
            email: '',
            telephone: '',
            intTelephone: '',
            address: {},

            userName: '',
            userSubject: '',
            userEmail: '',
            userMessage: '',
            mailSending: false,
            mailSent: false,
            mailError: false,
            helperMessage: ''
        }
    }

    async componentDidMount() {
        try {
            // Fetch the contact data from the backend
            const contactData = await axios.get(`${config.API}/pages/228`);

            this.setState({
                contentLoaded: true,
                headerTitle: contactData.data.acf.contact_header.header_title,
                headerText: contactData.data.acf.contact_header.header_text,
                email: contactData.data.acf.contact_details.email,
                telephone: contactData.data.acf.contact_details.telephone,
                intTelephone: `+44 ${contactData.data.acf.contact_details.telephone.slice(1)}`,
                address: contactData.data.acf.contact_details.address
            });
        } catch {
            this.context.dispatchError("Contact data could not be loaded. Please click here to reload the site.", true);
        }

    }

    onFormChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        const { userName, userSubject, userEmail, userMessage, mailSending, mailSent, mailError } = this.state;

        // Validate user input
        if(userName === '' || userSubject === '' || userEmail === '' || userMessage === '') {
            this.setState({helperMessage: 'Please enter all fields.'});
            return;
        } else if(!this.validEmail(userEmail)) {
            this.setState({ helperMessage: 'Please enter a valid email.'});
            return;
        } else if(userMessage.length < 20) {
            this.setState({ helperMessage: 'Message too short.'});
            return;
        } else if(mailSending || mailSent || mailError) {
            return;
        }

        this.setState({mailSending: true})

        axios({
            method: 'POST',
            url: config.email,
            headers: { 'Content-Type': 'application/json'},
            data: {
                "userName": userName,
                "userSubject": userSubject,
                "userEmail": userEmail,
                "userMessage": userMessage
            }
        }).then(res => {
            this.setState({ mailSending: false, mailSent: true, helperMessage: '' })
        }).catch(err => {
            this.setState({ mailSending: false, mailError: true, helperMessage: '' })
        })
        
    }

    formatEmail = () => {
        let emailSplit = this.state.email.split('@');
        
        return <a href={`mailto:${this.state.email}`}>{emailSplit[0]}<br/>@{emailSplit[1]}</a>
    }

    validEmail = (userEmail) => {
        const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return emailRegEx.test(userEmail);
    }

    render() {
        const contentVisible = this.props.contentVisible;
        const contentLoaded = this.state.contentLoaded;
        const { mailSending, mailSent, mailError } = this.state;

        if(!contentLoaded) {
            return (
                <div className="contact-container">
                    <div className="form-white-wrapper">
                    <div className="form-container">

                    </div>
                    </div>

                    <div className="details-white-wrapper">
                    <div className="details-container">

                    </div>
                    </div>
                </div>
            )
        } else {

        return (
            <div className="contact-container">

                <div className="form-white-wrapper">
                <div className="form-container">

                <CSSTransition in={contentVisible} timeout={1000}
                appear={true} enter={true} exit={true}
                classNames="form-content-fade">
                <div className="form-content-container">

                    <section className="callout">
                        <h3 className="callout-title">{this.state.headerTitle}</h3>
                        <p className="callout-text">{this.state.headerText}</p>
                    </section>
                    <form className="contact-form">
                        <div className="input-container">
                            <label htmlFor="userName">Name:</label>
                                <input id="userName" type="text" onChange={this.onFormChange}/>
                            <label htmlFor="userSubject">Subject:</label>
                                <input id="userSubject" type="text" onChange={this.onFormChange}/>
                            <label htmlFor="userEmail">Email:</label>
                                <input id="userEmail" type="email" onChange={this.onFormChange}/>
                            <label htmlFor="userMessage">Message:</label>
                                <textarea id="userMessage" onChange={this.onFormChange}></textarea>
                            <p className="helper-message">{this.state.helperMessage}</p>
                            <button type="submit" value="Send" onClick={this.onFormSubmit}>{mailSending ? 'Sending' : mailError ? 'Error: Reload To Try Again' : mailSent ? 'Message Sent' : 'Send'}</button>
                        </div>
                    </form>
                    </div>
                </CSSTransition>
                </div>
                </div>

                <div className="details-white-wrapper">
                <div className="details-container">

                <CSSTransition in={contentVisible} timeout={1000}
                appear={true} enter={true} exit={true}
                classNames="details-content-fade">
                <div className="details-content-container">

                    <div className="details-box">
                        <div className="telephone-details">
                            <svg className="phone-vector" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.984 42.794">
                                <g id="layer1" transform="translate(-2264.415 -992.236)">
                                    <g id="g21424" transform="translate(2265.915 993.748)">
                                    <path id="path4-8" d="M-194.24,1223.19c-.03.711-.04,1.4-.04,2.123a12.785,12.785,0,0,0,1.322,4.385,13.78,13.78,0,0,0,4.806,4.426,5.142,5.142,0,0,0,4.025.38,43.8,43.8,0,0,0,28.536-28.376,5.814,5.814,0,0,0-.491-4.636,15.633,15.633,0,0,0-2.073-2.833,12.745,12.745,0,0,0-6.879-3.655,1.021,1.021,0,0,0-.911.29.954.954,0,0,0-.24.481l-2.573,10.263a1.006,1.006,0,0,0,.7,1.221l3.194.931a.975.975,0,0,1,.451.27,1.022,1.022,0,0,1-.02,1.462l-15.389,15.389a1.022,1.022,0,0,1-1.462.02,1.152,1.152,0,0,1-.28-.521l-.37-1.953a1.065,1.065,0,0,0-1.031-.831l-10.263.21a1.074,1.074,0,0,0-.691.31,1.3,1.3,0,0,0-.32.641Z" transform="translate(194.28 -1194.985)"/>
                                    </g>
                                </g>
                            </svg>

                            <a href={`tel:${this.state.intTelephone}`}>{this.state.telephone}</a>
                        </div>
                        <div className="email-details">
                            <svg className="email-vector" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 35.714">
                                <g id="layer1" transform="translate(-17 42)">
                                    <path id="rect3174" d="M9.357,8A5.355,5.355,0,0,0,4,13.357c.066,8.123,0,16.721,0,25a5.335,5.335,0,0,0,5.022,5.329l39.844.028A5.356,5.356,0,0,0,54,38.357V12.436A5.343,5.343,0,0,0,48.727,8q-19.674.009-39.37,0Zm-.084,2.818H48.894L29.725,29.987a.882.882,0,0,1-1.256,0L9.273,10.818Zm42.048,1.368V38.162c0,1.548-.558,2.422-1.674,2.734l-13.5-13.532L51.322,12.185ZM6.986,12.3,21.913,27.224,8.269,40.868a1.964,1.964,0,0,1-1.283-2.092V12.3Zm16.825,16.8,4.66,4.688a.882.882,0,0,0,1.256,0l4.52-4.52L46.02,41.036H11.868L23.81,29.094Z" transform="translate(13 -50)"/>
                                </g>
                            </svg>

                            {this.formatEmail()}
                        </div>
                        <div className="address-details">
                            <svg className="address-vector" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.46 42.054">
                                <g id="layer1" transform="translate(-247.868 -448.035)">
                                    <g id="g8181" transform="translate(249.135 449.285)">
                                    <path id="path8166" className="address-vector-1" d="M881.14,910.672c-9.6-.025-12.011,6.432-12,10.247.018,8.227,4.9,14.526,12.119,24.792,7.05-10.377,11.8-16.549,11.8-24.761C893.055,916.9,890.736,910.7,881.14,910.672Zm-.116,5.2c3.3,0,5.975,2.306,5.976,5.151s-2.676,5.151-5.976,5.151-5.974-2.306-5.974-5.151S877.725,915.867,881.024,915.867Z" transform="translate(-864.132 -910.672)"/>
                                    <path id="path8172" className="address-vector-2" d="M885.815,961.827c7.235.916,11.156,3.084,9.419,5.208s-8.662,3.632-16.637,3.621-14.846-1.536-16.507-3.665,2.337-4.286,9.6-5.183" transform="translate(-861.716 -931.102)"/>
                                    </g>
                                </g>
                            </svg>
                        
                            {Object.keys(this.state.address).map((line, i) => {
                            return <p className={i === 0 ? 'top-line' : ''} key={i}>{this.state.address[line]}</p>
                            })}
                            
                        </div>
                    </div>

                </div>
                </CSSTransition>

                </div>
                </div>
            </div>
        )
        }
    }
}

export default Contact;
