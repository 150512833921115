import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';

import { NotificationContext } from '../../../components/Notifier/Notifier';

import "./MyWork.css";

import config from '../../../config/config.json';

import WorkTile from './WorkTile/WorkTile';
import WorkFocus from './WorkFocus/WorkFocus';
import ImageFocus from './ImageFocus/ImageFocus';

import LoadTrigger from '../../../components/LoadTrigger/LoadTrigger';

/*
*  stateTypes:
*
*  {
*    page: number;
*    complete: boolean;
*    works: Array<WorkObjects>;
*    selectedWork: WorkObject | undefined;
*    selectedImage: string | undefined;
*    windowScrollPosition: number;
*    galleryScrollPosition: number;
*  }
*/

const endpoint = `${config.API}/works`;

class MyWork extends React.Component {
    static contextType = NotificationContext;

    constructor() {
        super()

        this.galleryContainerRef = React.createRef();
    }

    state = {
        page: 1,
        complete: false,
        works: [],

        selectedWork: undefined,
        selectedImage: undefined,

        windowScrollPosition: 0,
        galleryScrollPosition: 0
    }

    /* Load Works */

    loadWorks = async () => {
        try {
            const { page, works } = this.state;

            const thisPage = await axios.get(`${endpoint}?page=${page}`);
            const nextPage = page + 1;
            const totalPages = thisPage.headers["x-wp-totalpages"];
    
            const morePages = nextPage <= totalPages;
    
            this.setState({
                page: morePages ? nextPage : page,
                works: works.concat(thisPage.data),
                complete: !morePages
            });
        } catch {
            this.context.dispatchError("Work data could not be loaded. Please click here to reload the site.", true);
        }
    }

    /* Scroll Handlers */

    saveScroll = () => {
        this.setState({
            windowScrollPosition: window.pageYOffset,
            galleryScrollPosition: this.galleryContainerRef.current.scrollTop
        })
    }

    returnScroll = () => {
        this.galleryContainerRef.current.scrollTo({top: this.state.galleryScrollPosition});
        window.scrollTo({top: this.state.windowScrollPosition});

        this.setState({
            windowScrollPosition: 0,
            galleryScrollPosition: 0
        })
    }

    /* Select Handlers */

    handleSelectWork = (id) => {
        if (!id) {
            return this.setState({
                selectedWork: undefined,
                selectedImage: undefined
            }, () => this.returnScroll());
        }

        const selectedWork = this.state.works.find(work => work.id === id);
        if (selectedWork) this.setState({selectedWork});

        this.saveScroll();
        window.scrollTo({top: 0});
        this.galleryContainerRef.current.scrollTo({top: 0});
    }

    handleSelectImage = (path) => {
        if (!path) {
            return this.setState({ selectedImage: undefined });
        }

        this.setState({ selectedImage: path });
    }

    /* Render Functions */

    renderWorkTiles = () => {
        const { selectedWork, selectedImage } = this.state;

        if (selectedWork || selectedImage) return null;

        return (
            <div className="mywork-gallery-grid">
                {this.state.works.map(work => {
                    const { id, acf: { sold_status, gallery } } = work;

                    return (
                        <WorkTile 
                            key={id}
                            id={id}
                            isSold={sold_status === "sold"} 
                            featuredImg={gallery[0]["full_image_url"]}
                            gallerySize={gallery.length}
                            onSelectWork={this.handleSelectWork}
                        />
                    )
                })}
                <LoadTrigger
                    className="mywork-load-trigger"
                    onTrigger={this.loadWorks}
                    renderLoading={() => <p>Loading...</p>}
                    renderWaiting={() => <p>Waiting...</p>}
                    complete={this.state.complete}
                />
            </div>
        );
    }

    renderSelectedWork = () => {
        const { selectedWork, selectedImage } = this.state;

        if (!selectedWork || selectedImage) return null;
        
        return (
            <WorkFocus 
                work={selectedWork}
                onUnselectWork={this.handleSelectWork}
                onSelectImage={this.handleSelectImage}
            />
        )
    }

    renderSelectedImage = () => {
        const { selectedImage } = this.state;

        if (!selectedImage) return null;

        return (
            <ImageFocus
                path={selectedImage}
                onUnselectImage={this.handleSelectImage}
            />
        )
    }

    render() {
        const { contentVisible } = this.props;
        
        return (
            <div className="mywork-white-wrapper">
                <div className="mywork-brown-container">
                    <CSSTransition 
                        timeout={1000}
                        in={contentVisible} 
                        appear={true}
                        enter={true} 
                        exit={true}
                        classNames="mywork-content-fade"
                    >
                        <div className="mywork-content-container">
                            <div ref={this.galleryContainerRef} className="mywork-gallery-container">
                                {this.renderWorkTiles()}
                                {this.renderSelectedWork()}
                                {this.renderSelectedImage()}
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
        )
    }
}

MyWork.propTypes = {
    viewport: PropTypes.oneOf(['web', 'mobile']),
    contentVisible: PropTypes.bool
}

export default MyWork;