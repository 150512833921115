import React from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';

import { NotificationContext } from '../../../components/Notifier/Notifier';

import config from './../../../config/config';

import './About.css'

import Bio from './Bio/Bio';
import Events from './Events/Events';

class About extends React.Component {
    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            eventsView: false,
            transitionActive: false,
            contentLoaded: false,
            viewPort: window.innerWidth >= 730 ? 'web' : 'mobile',

            bioText: '',
            events: []
        }

        this.startViewTransition = this.startViewTransition.bind(this);
        this.endViewTransition = this.endViewTransition.bind(this);
        this.renderBio = this.renderBio.bind(this);
        this.renderEvents = this.renderEvents.bind(this);
    }

    async componentDidMount() {
        try {
            // Fetch the event and bio data from the backend
            const eventsData = await axios.get(`${config.API}/events`);
            const events = eventsData.data.map(eventObj => ({
                title: eventObj.title.rendered,
                ...eventObj.acf
            })).sort((a, b) => b.date.start_date - a.date.start_date);

            const bioData = await axios.get(`${config.API}/pages/226`);
            const bioText = bioData.data.acf.text.split("\n\r");

            this.setState({
                bioText,
                events,
                contentLoaded: true
            });
        } catch (e) {
            this.context.dispatchError("About data could not be loaded. Click here to reload the site.", true);
        }
        
    }

    componentDidUpdate() {
        if(this.props.viewPort !== this.state.viewPort) {
            this.setState({
                viewPort: this.props.viewPort
            });
        };
    }

    startViewTransition() {
        this.setState({
            eventsView: !this.state.eventsView,
            transitionActive: true
        })
    }
    
    endViewTransition() {
        this.setState({
            transitionActive: false
        })
    }

    renderBio() {
        if(this.state.contentLoaded === true) {
            return (
                <Bio bioText={this.state.bioText} viewPort={this.state.viewPort} eventsView={this.state.eventsView} startTransition={this.startViewTransition} endTransition={this.endViewTransition} transitionActive={this.state.transitionActive}/>
            )
        }
    }

    renderEvents () {
        if(this.state.contentLoaded === true) {
            return (
                <Events events={this.state.events} viewPort={this.state.viewPort} eventsView={this.state.eventsView} startTransition={this.startViewTransition} endTransition={this.endViewTransition} transitionActive={this.state.transitionActive} handleNavClick={this.props.handleNavClick}/>
            )
        }
    }

    render() {
        const contentVisible = this.props.contentVisible;
        return (
            <div className="about-container">
                
                <CSSTransition
                in={this.state.eventsView} timeout={500}
                appear={false} enter={true}
                exit={true} classNames="aboutShift">
                    <div className="bio-container">

                        <CSSTransition
                        in={contentVisible} timeout={1000}
                        appear={true} enter={true} exit={true} classNames="bio-content-container-fade">
                            <div className="bio-content-container">
                                {this.renderBio()}
                            </div>
                        </CSSTransition>

                    </div>
                </CSSTransition>

                <CSSTransition
                in={this.state.eventsView} timeout={500}
                appear={false} enter={true}
                exit={true} classNames="aboutShift">
                    <div className="events-container">
                        

                    <CSSTransition
                        in={contentVisible} timeout={1000}
                        appear={true} enter={true} exit={true} classNames="events-content-container-fade">
                            <div className="events-content-container">
                                {this.renderEvents()}
                            </div>
                    </CSSTransition>


                    </div>
                </CSSTransition>
            </div>
        )
    }
}

export default About;
