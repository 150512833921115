import React from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';

import { NotificationContext } from '../../../components/Notifier/Notifier';

import config from '../../../config/config';
import './Location.css';

class Location extends React.Component {
    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        this.state = {
            contentLoaded: false,
            addressString: ''
        }
    }

    async componentDidMount() {
        try {
            const contactData = await axios.get(`${config.API}/pages/228`);

            const addressObj = contactData.data.acf.contact_details.address;
            const addressString = 
                Object.keys(addressObj)
                .filter(line => addressObj[line] !== '')
                .map(line => addressObj[line].split(' ')).join('+');
    
            this.setState({
                contentLoaded: true,
                addressString
            });
        } catch {
            this.context.dispatchError("Contact data could not be loaded. Please click here to reload the site.", true);
        }
    }

    render() {
        const contentVisible = this.props.contentVisible;
        const contentLoaded = this.state.contentLoaded;

        if(!contentLoaded) {
            return (
                <div className="location-white-wrapper">
                <div className="location-container">

                </div>
                </div>
                )
        } else {
            return (
                <div className="location-white-wrapper">
                <div className="location-container">

                    <CSSTransition in={contentVisible} timeout={1000}
                    appear={true} enter={true} exit={true}
                    classNames="map-wrapper-fade">
                    <div className="map-wrapper">
                        <div className="map-sizer">
                            <iframe title="location-map" className="map-frame" src={`https://www.google.com/maps/embed/v1/place?q=${this.state.addressString}&zoom=12&key=AIzaSyBa3DWtdt5t0wZDRNeb_1Kf5mRgS4JmPKg`} allowFullScreen></iframe>
                        </div>
                    </div>
                    </CSSTransition>

                </div>
                </div>
            )
        }
    }
}

export default Location;