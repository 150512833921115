import React from 'react';
import PropTypes from 'prop-types';

import "./ImageTile.css";

import SquaredContainer from '../../../../components/SquaredContainer/SquaredContainer';

class ImageTile extends React.Component {
    handleSelectImage = () => {
        this.props.onSelectImage(this.props.path);
    }

    render() {
        const { path, workId, imageId } = this.props;

        return (
            <SquaredContainer className="image-tile" onClick={this.handleSelectImage}>
                <img style={{visibility: 'hidden'}} onLoad={(e) => e.target.style.visibility = "visible"} src={path} alt={`Work ${workId} Img ${imageId}`}/>
            </SquaredContainer>
        )
    }
}

ImageTile.propTypes = {
    workId: PropTypes.number,
    imageId: PropTypes.number,
    path: PropTypes.string,
    onSelectImage: PropTypes.func
}

export default ImageTile;