import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './Embellishment.css';

class Embellishment extends React.Component {
    render() {
        const currentRouteClass = this.props.currentRoute.slice(1);

        return (
            <div className={`embellishment-container ${currentRouteClass}`}>
                <CSSTransition 
                    in={this.props.isVisible} 
                    timeout={1000} appear={true} 
                    enter={true} exit={true} 
                    classNames="animation">
                    <div className={`embellishment ${currentRouteClass}`} key="embellishment"></div>
                </CSSTransition>
            </div>
        )
    }
}

export default Embellishment;