import React from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import './Navigation.css';

class Navigation extends React.Component {
    render() {
        const currentLayout = this.props.currentLayout;
        const currentRoute = this.props.currentRoute;
        const nextRoute = this.props.nextRoute;
        const navShift = this.props.navShift;
        //const isFirefox = typeof InstallTrigger !== 'undefined' ? 'isFirefox' : 'notFirefox';
        //const isEdge = /Edge/.test(navigator.userAgent) ? 'isEdge' : 'notEdge';
        const browser = typeof InstallTrigger !== 'undefined' ? 'isFirefox' :
                        /Edge/.test(navigator.userAgent) ? 'isEdge' : 'otherBrowser';

        return (
            <CSSTransition
                in={navShift}
                timeout={1000} appear={false}
                enter={true} exit={true}
                classNames="gridShift">
                <nav className={`nav-container ${currentLayout}`}>
                    <li className={`nav-link about vert ${browser}`}>
                        <p className={
                            currentRoute === '/about' && nextRoute === '' ? 'selected' : ''} 
                            onClick={() => this.props.history.push('/about')}>ABOUT</p>
                    </li>
                    <li className={`nav-link location vert ${browser}`}>
                        <p className={
                            currentRoute === '/location' && nextRoute === '' ? 'selected' : ''} 
                            onClick={() => this.props.history.push('/location')}>LOCATION</p>
                    </li>
                    <li className={`nav-link work ${browser}`}>
                        <p 
                        className={currentRoute === '/mywork' && nextRoute === '' ? 'selected' : ''} 
                        onClick={() => this.props.history.push('/mywork')}>MY WORK</p>
                    </li>
                    <li className={`nav-link contact ${browser}`}>
                        <p 
                        className={currentRoute === '/contact' && nextRoute === '' ? 'selected' : ''} 
                        onClick={() => this.props.history.push('/contact')}>CONTACT</p>
                    </li>
                </nav>
            </CSSTransition>
        )
    }
}

export default withRouter(Navigation);