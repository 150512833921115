import React from 'react';
import PropTypes from 'prop-types';

import SquaredContainer from '../../../../components/SquaredContainer/SquaredContainer';

import "./ImageFocus.css";

class ImageFocus extends React.Component {
    handleUnselectImage = () => {
        this.props.onUnselectImage();
    }

    render() {
        const { path } = this.props;

        return (
            <SquaredContainer className="image-focus">
                <button className="image-focus-back" onClick={this.handleUnselectImage}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
                </button>
                <img src={path} alt={`Focussed Work Img`}/>
            </SquaredContainer>
        );
    }
}

ImageFocus.propTypes = {
    path: PropTypes.string,
    onUnselectImage: PropTypes.func
}

export default ImageFocus;