import React from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
 
import './Icon.css';

class Icon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstIconPath: '',
            firstIconActive: true,
            secondIconPath: '',
            secondIconActive: false,
            iconState: 0,
            iconPaths: {
                '/': require("../../../logos/aboutLogo.png"),
                about: require("../../../logos/aboutLogo.png"),
                dashboard: require("../../../logos/aboutLogo.png"),
                contact: require("../../../logos/contactLogo.png"),
                location: require("../../../logos/locationLogo.png"),
                mywork: require("../../../logos/workLogo.png")
            },
        }

        this.getIconPathFromRoute = this.getIconPathFromRoute.bind(this);
        this.transitionIcon = this.transitionIcon.bind(this);
    }

    componentDidMount() {
        const entryPath = this.getIconPathFromRoute(window.location.pathname);
        this.setState({
            firstIconPath: entryPath,
            secondIconPath: entryPath
        });
    }

    componentDidUpdate(prevProps) {
        if(this.props.currentRoute !== prevProps.currentRoute) {
            const newIconPath = this.getIconPathFromRoute(this.props.currentRoute);
            this.transitionIcon(newIconPath);
        }
    }

    getIconPathFromRoute(route) {
        let pathConstructor = route === '/' ? '/' : route.slice(1);
        return this.state.iconPaths[pathConstructor];
    }

    transitionIcon(newIconPath) {
        if(this.state.iconState === 0) {
            this.setState({
                secondIconPath: newIconPath,
                firstIconActive: false,
                secondIconActive: true,
                iconState: 1
            });
        } else {
            this.setState({
                firstIconPath: newIconPath,
                firstIconActive: true,
                secondIconActive: false,
                iconState: 0
            });
        }
    }

    render() {
        return (
            <CSSTransition 
                in={this.props.isVisible} 
                timeout={1000} appear={true} 
                enter={true} exit={true} 
                classNames="animation">
                <div className={`icon-container`} key="icon-container">
                    <CSSTransition
                        in={this.state.firstIconActive}
                        timeout={1000} appear={false}
                        enter={true} exit={true}
                        classNames="icon-transit">
                            <img className={`icon one`} alt="Ann Hebden Ceramics" src={this.state.firstIconPath} value="/" onClick={() => this.props.history.push('/')}></img>
                    </CSSTransition>
                    <CSSTransition
                        in={this.state.secondIconActive}
                        timeout={1000} appear={false}
                        enter={true} exit={true}
                        classNames="icon-transit">
                            <img className={`icon two`} alt="Ann Hebden Ceramics" src={this.state.secondIconPath} value="/" onClick={() => this.props.history.push('/')}></img>
                    </CSSTransition>
                </div>
            </CSSTransition>
        )
    }
}



export default withRouter(Icon);