import React from 'react';
import PropTypes from 'prop-types';

import SquaredContainer from '../../../../components/SquaredContainer/SquaredContainer';

import "./WorkTile.css";

class WorkTile extends React.Component {
    handleSelectWork = () => {
        this.props.onSelectWork(this.props.id);
    }

    render() {
        const { id, isSold, featuredImg, gallerySize } = this.props;

        return (
            <SquaredContainer className="work-tile" onClick={this.handleSelectWork}>
                <img
                    alt={`Work ${id} Featured Ceramic`}
                    src={featuredImg}
                    style={{visibility: 'hidden'}} onLoad={(e) => e.target.style.visibility = "visible"}
                />
                {isSold ? <p className="work-sold-tag">SOLD</p> : null}
                <div className="work-count-tag">
                    <p>{gallerySize}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ><path d="M20,2H8C6.897,2,6,2.897,6,4v12c0,1.103,0.897,2,2,2h12c1.103,0,2-0.897,2-2V4C22,2.897,21.103,2,20,2z M8,16V4h12 l0.002,12H8z"></path><path d="M4,8H2v12c0,1.103,0.897,2,2,2h12v-2H4V8z"></path><path d="M12 12L11 11 9 14 19 14 15 8z"></path></svg>
                </div>
            </SquaredContainer>
        )
    }
}

WorkTile.propTypes = {
    id: PropTypes.number,
    isSold: PropTypes.bool,
    featuredImg: PropTypes.string,
    gallerySize: PropTypes.number,
    onSelectWork: PropTypes.func
}

export default WorkTile;