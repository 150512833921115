import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import './RouteViews.css';

import Landing from './Landing/Landing';
import About from './About/About';
import MyWork from './MyWork/MyWork';
import Contact from './Contact/Contact';
import Location from './Location/Location';

class RouteViews extends React.Component {

    render() {
        const { siteEntry, contentVisible, viewPort, handleNavClick, currentLocation } = this.props;

        return (
            <CSSTransition
                in={contentVisible && !siteEntry}
                timeout={1000} appear={true}
                enter={true} exit={true}
                classNames="animation"
                onExited={() => this.props.transitionHandler()}>

                <div className={`content-frame`} key="content-frame">
                    <Switch location={currentLocation}>
                        <Route exact path="/" render={(props) => <Landing contentVisible={contentVisible} siteEntry={siteEntry} viewPort={viewPort}/>}/>

                        <Route path="/about" render={(props) => <About viewPort={viewPort} contentVisible={contentVisible} handleNavClick={handleNavClick}/>} />

                        <Route path="/mywork" render={(props) => <MyWork viewPort={viewPort} contentVisible={contentVisible}/>} />

                        <Route path="/contact" render={(props) => <Contact contentVisible={contentVisible}/>} />

                        <Route path="/location" render={(props) => <Location contentVisible={contentVisible}/>} />
                        
                        <Redirect to="/" />
                    </Switch>
                </div>
                
            </CSSTransition>
        )
    }
}

export default RouteViews;