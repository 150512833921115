import React from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import './Main.css';

import Navigation from './Navigation/Navigation';
import Icon from './Icon/Icon';
import Embellishment from './Embellishment/Embellishment';
import RouteViews from '../RouteViews/RouteViews';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            siteEntry : true,
            viewPort: '',

            currentLayout: '',
            nextLayout: '',
            currentRoute: '',
            nextRoute: '',

            contentVisible: true,
            gridShift: false,
            navShift: false,
            iconActive: '',
            iconVisible: '',
            embellishmentActive: '',
            embellishmentVisible: '',

            transitionPath: '',
            pendingLocation: {},
            currentLocation: this.props.location
        }

        this.unlisten = this.props.history.listen(this.onRouteChange);

        this.renderIcon = this.renderIcon.bind(this);
        this.renderEmbellishment = this.renderEmbellishment.bind(this);

        this.updateViewPort = this.updateViewPort.bind(this);
        this.handleNavClick = this.handleNavClick.bind(this);
        this.animationMiddleware = this.animationMiddleware.bind(this);
        this.initiateRedirect = this.initiateRedirect.bind(this);
        this.transitionOnNewView = this.transitionOnNewView.bind(this);
    }

    componentDidMount() {
        const entryRoute = window.location.pathname;
        const entryLayout = entryRoute === '/' ? 'land' : 'content';
        const entryView = window.innerWidth >= 730 ? 'web' : 'mobile';
        const entryIcon = entryLayout === 'content' ? true : false;
        const entryEmbellish = entryLayout === 'content' ? true : false;

        this.setState({
            viewPort: entryView,
            currentLayout: entryLayout,
            currentRoute: entryRoute,
            iconActive: entryIcon,
            iconVisible: entryIcon,
            embellishmentActive: entryEmbellish,
            embellishmentVisible: entryEmbellish
        });

        window.addEventListener('resize', this.updateViewPort);
    }

    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener('resize', this.updateViewPort);
    }

    onRouteChange = (location) => {
        this.setState({
            pendingLocation: location
        }, () => this.handleNavClick())
    }

    updateViewPort() {
        let newView =  window.innerWidth >= 730 ? 'web' : 'mobile';
        if (this.state.viewPort !== newView) {
            this.setState({viewPort: newView});
        };
    }

    handleNavClick() {
        const { currentRoute, viewPort, pendingLocation } = this.state;
        const nextRoute = pendingLocation.pathname;

        if(currentRoute === nextRoute) {
            return;
        } else {
            // Force scrolling behaviour during transition
            if (viewPort === 'mobile') {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            };
            window.addEventListener('scroll', lockScrolling);

            this.setState({
                nextRoute: nextRoute,
                workCloseUpActive: false,
                workTransitionActive: false
            });
            if(currentRoute === '/'){
                // Transition from Landing
                this.setState({
                    nextLayout: 'content',
                    transitionPath: 'L2C',
                    navShift: true,
                    contentVisible: false});
            } else {
                if(nextRoute === '/') {
                    // Transition to Landing
                    this.setState({
                        nextLayout: 'land',
                        transitionPath: 'C2L',
                        navShift: true,
                        embellishmentVisible: false,
                        contentVisible: false,
                        iconVisible: false
                    });
                } else {
                    // Transition between Content
                    this.setState({
                        nextLayout: 'content',
                        transitionPath: 'C2C',
                        embellishmentVisible: false,
                        contentVisible: false
                    });
                }
            }
        }
    }

    // Called sequentially by content-frame and grid spacer transitions
    animationMiddleware() {
        const transitionPath = this.state.transitionPath;
        const nextRoute = this.state.nextRoute;
        const viewPort = this.state.viewPort;
        const currentRoute = this.state.currentRoute;

        if(transitionPath === 'L2C' || transitionPath === 'L2CShift') {
            if(transitionPath === 'L2C') {
                this.setState({
                    gridShift: true,
                    transitionPath: 'L2CShift'
                });
            } else {
                this.initiateRedirect();
            }
        } else if(transitionPath === 'C2L' || transitionPath === 'C2LShift') {
            if(transitionPath === 'C2L') {
                this.setState({
                    gridShift: true,
                    embellishmentActive: false,
                    iconActive: false,
                    transitionPath: 'C2LShift'
                })
            } else {
                this.initiateRedirect();
            }
        } else {
            if(transitionPath === 'C2C') {
                if(viewPort === 'mobile' && (nextRoute === '/mywork' || currentRoute === '/mywork')) {
                    this.setState({
                        gridShift: true,
                        embellishmentActive: false,
                        transitionPath: 'C2CShift'
                    });
                } else {
                    this.setState({
                        embellishmentActive: false
                    }, () => {
                        this.initiateRedirect();
                    })
                }
            } else {
                this.initiateRedirect();
            }
        }
    }

    initiateRedirect() {
        this.setState({currentLocation: this.state.pendingLocation}, () => {
            this.setState({
                currentRoute: this.state.nextRoute,
                nextRoute: '',

                currentLayout: this.state.nextLayout,
                nextLayout: '',

                pendingLocation: {}
            }, () => {
                this.transitionOnNewView();
            })
        })
    }

    transitionOnNewView() {
        window.removeEventListener('scroll', lockScrolling);

        if(this.state.transitionPath === 'L2CShift') {
            this.setState({
                embellishmentActive: true,
                embellishmentVisible: true,
                iconActive: true,
                iconVisible: true,
                contentVisible: true,
                gridShift: false,
                navShift: false,
                transitionPath: ''
            })
        } else if(this.state.transitionPath === 'C2LShift') {
            this.setState({
                contentVisible: true,
                gridShift: false,
                navShift: false,
                transitionPath: ''
            })
        } else {
            this.setState({
                embellishmentActive: true,
                embellishmentVisible: true,
                contentVisible: true,
                gridShift: false,
                transitionPath: ''
            })
        }
    }

    renderIcon() {
        const siteEntry = this.state.siteEntry;
        const currentRoute = this.state.currentRoute;
        const currentLayout = this.state.currentLayout;
        const iconActive = this.state.iconActive;
        const iconVisible = this.state.iconVisible;

        if(currentLayout === 'content' && iconActive && !siteEntry) {
            return <Icon isVisible={iconVisible} currentRoute={currentRoute} handleNavClick={this.handleNavClick}/>
        } else {
            return null;
        }
    }

    renderEmbellishment() {
        const siteEntry = this.state.siteEntry;
        const currentLayout = this.state.currentLayout;
        const currentRoute = this.state.currentRoute;
        const embellishActive = this.state.embellishmentActive;
        const embellishVisible = this.state.embellishmentVisible;

        if(currentLayout === 'content' && embellishActive && !siteEntry) {
            return <Embellishment isVisible={embellishVisible} currentRoute={currentRoute}/>
        } else {
            return null;
        }
    }

    render() {
        const siteEntry = this.state.siteEntry;
        const viewPort = this.state.viewPort;
        const gridShift = this.state.gridShift;
        const navShift = this.state.navShift;
        const currentLayout = this.state.currentLayout;
        const currentRoute = this.state.currentRoute;
        const nextRoute = this.state.nextRoute;

        return (
            <CSSTransition 
                in={siteEntry} 
                timeout={300} appear={true} 
                enter={false} exit={false}
                onEntered={() => this.setState({siteEntry: false})}
                classNames="animation">

                <div className={`meta-grid ${currentLayout} ${siteEntry === true ? 'enter' : 'active'}`} key="meta-grid">

                    <Navigation currentLayout={currentLayout} currentRoute={currentRoute} nextRoute={nextRoute} navShift={navShift} handleNavClick={this.handleNavClick}/>
                    {this.renderIcon()}
                    {this.renderEmbellishment()}

                    <CSSTransition in={gridShift} timeout={1000} appear={false}
                        enter={true} exit={false}
                        onEntered={() => this.animationMiddleware()}
                        classNames="gridShift">
                        <div className={`left-spacer ${currentLayout} ${nextRoute === '/' ? 'nextLand' : 'nextOther'} ${'currOther'}`}></div>
                    </CSSTransition>
                    <CSSTransition in={gridShift} timeout={1000} appear={false}
                        enter={true} exit={false}
                        classNames="gridShift">
                        <div className={`right-spacer ${currentLayout} ${nextRoute === '/' ? 'nextLand' : 'nextOther'} ${'currOther'}`}></div>
                    </CSSTransition>
                    <CSSTransition in={gridShift} timeout={1000} appear={false}
                        enter={true} exit={false}
                        classNames="gridShift">
                        <div className={`top-spacer ${currentLayout} ${nextRoute === '/' ? 'nextLand' : 'nextOther'} ${'currOther'}`}></div>
                    </CSSTransition>
                    
                    <CSSTransition
                    in={gridShift} 
                    timeout={1000} appear={false}
                    enter={true} exit={false}
                    classNames="routeShift">
                        <div className={`route-frame ${currentLayout} ${nextRoute === '/' ? 'nextLand' : 'nextOther'} ${'currOther'}`}>
                            
                            <CSSTransition
                                in={gridShift}
                                timeout={1000} appear={false}
                                enter={true} exit={false}
                                classNames="square-mutate">
                                <div className={`placeholders ${currentLayout} ${nextRoute === '/' ? 'nextLand' : 'nextOther'}`}>
                                    <div className="square first"></div>
                                    <div className="square second"></div>
                                    <div className="square third"></div>
                                    <div className="square fourth"></div>
                                    <div className="square fifth"></div>
                                    <div className="square sixth"></div>
                                    <div className="square seventh"></div>
                                    <div className="square eighth"></div>
                                    <div className="square ninth"></div>
                                </div>
                            </CSSTransition>

                            <RouteViews contentVisible={this.state.contentVisible} siteEntry={siteEntry} viewPort={viewPort} transitionHandler={this.animationMiddleware} handleNavClick={this.handleNavClick} currentLocation={this.state.currentLocation}/>
                            
                        </div>
                    </CSSTransition>
                    
                </div>

            </CSSTransition>
        )
    }
}

function lockScrolling() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
}

export default withRouter(Main);