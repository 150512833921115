import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Notifier from './components/Notifier/Notifier';
import CacheBuster from './views/CacheBuster/CacheBuster';
import Main from './views/Main/Main';

const RootHTML = () => (
    <CacheBuster>
        <BrowserRouter>
            <Notifier>
                <Main/>
            </Notifier>
        </BrowserRouter>
    </CacheBuster>
);

ReactDOM.render(<RootHTML />, document.getElementById('root'));