import React from 'react';
import PropTypes from 'prop-types';

import "./WorkFocus.css";

import ImageTile from '../ImageTile/ImageTile';

class WorkFocus extends React.Component {
    handleUnselectWork = () => {
        this.props.onUnselectWork();
    }

    render() {
        const { id, acf: { title, description, dimensions, price, sold_status, gallery } } = this.props.work;
        const { height, width, depth } = dimensions;

        const renderField = (label, content) => {
            return (
                <div className="focus-details-field">
                    <p className="focus-details-field-label"><b>{label}:</b></p>
                    <p className="focus-details-field-content">{content}</p>
                </div>
            )
        }

        const renderDimensions = () => {
            if (!height && !width && !depth) return null;

            const cmString = (d, str) => `${d} ${str}cm`

            const hString = height ? cmString('H:', height) : undefined;
            const wString = width ? cmString('W:', width) : undefined;
            const dString = depth ? cmString('D:', depth) : undefined;

            const content = [hString, wString, dString].filter(s => s).join(' x ');

            return renderField('Dimensions', content);
        }

        const renderSale = () => {
            return (
                <div className="focus-details-sale">
                    {price ? renderField('Price', `£${price}`) : null}
                    <p className={`focus-sold-tag ${sold_status}`}>{sold_status.toUpperCase()}</p>
                </div>
            )
        }

        return (
            <div className="work-focus-container">
                <div className="work-focus-controls">
                    <button className="work-focus-back" onClick={this.handleUnselectWork}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
                    </button>
                </div>
                <div className="work-focus-details">
                    {title ? renderField('Title', title) : null}
                    {renderField('ID', id)}
                    {description ? renderField('Description', description) : null}
                    {renderDimensions()}
                    {renderSale()}
                </div>
                <div className="work-focus-gallery">
                    {gallery.map(image => (
                        <ImageTile 
                            key={image.id} 
                            path={image.full_image_url} 
                            workId={id} 
                            imageId={image.id}
                            onSelectImage={this.props.onSelectImage}
                        />
                    ))}
                </div>
            </div>
        )
    }
}

WorkFocus.propTypes = {
    work: PropTypes.object,
    onUnselectWork: PropTypes.func,
    onSelectImage: PropTypes.func
}

export default WorkFocus;