import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './Bio.css';

class Bio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bioActive: true
        }

        this.handleBioClick = this.handleBioClick.bind(this);
        this.renderButtonContent = this.renderButtonContent.bind(this);
        this.renderBioContent = this.renderBioContent.bind(this);
        this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
    }

    componentDidUpdate() {
        const transitionActive = this.props.transitionActive;
        const eventsView = this.props.eventsView;
        const bioActive = this.state.bioActive;
        if(!transitionActive && eventsView && bioActive) {
            this.setState({
                bioActive: false
            })
        }
    }

    handleBioClick() {
        const transitionActive = this.props.transitionActive;
        if(!transitionActive) {
            this.props.startTransition();
        }
    }

    handleTransitionEnd() {
        this.props.endTransition();
        this.setState({
            bioActive: true
        })
    }

    renderButtonContent() {
        const transitionActive = this.props.transitionActive;
        if(this.state.bioActive === false) {
            return (
                <CSSTransition
                in={transitionActive} timeout={500}
                appear={false} enter={true}
                exit={false} classNames="bio-button-fade-out">
                <CSSTransition
                in={true} timeout={500}
                appear={true} enter={false}
                exit={false} classNames="bio-button-fade-in">
                    <div className="bio-button-items">

                        <h2 className="bio-button-title">bio</h2>
                        <svg className="backward-vector" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.572 34.288">
                            <g id="double_backward" data-name="double backward" transform="translate(-1258 -209)">
                                <path id="forwardArrow" d="M180.572,99.472,177.111,96,160,113.144h0l17.111,17.144,3.461-3.472-13.64-13.672Z" transform="translate(1119 113)"/>
                                <path id="forwardArrow-2" data-name="forwardArrow" d="M180.572,99.472,177.111,96,160,113.144h0l17.111,17.144,3.461-3.472-13.64-13.672Z" transform="translate(1098 113)"/>
                            </g>
                        </svg>

                    </div>
                </CSSTransition>
                </CSSTransition>
            )
        }
    }

    renderBioContent() {
        const transitionActive = this.props.transitionActive;
        if(this.state.bioActive === true || this.props.viewPort === 'mobile') {
            return (
                <CSSTransition
                in={transitionActive} timeout={200}
                appear={false} enter={true}
                exit={false} classNames="bio-content-fade-out">
                <CSSTransition
                in={true} timeout={500}
                appear={true} enter={false}
                exit={false} classNames="bio-content-fade-in">
                    <article className="bio-content">
                        {this.props.bioText.map((para, i) => {
                            return <p key={i}>{para}</p>
                        })}
                    </article>
                </CSSTransition>
                </CSSTransition>
            )
        }
    }

    render() {
        const transitionActive = this.props.transitionActive;

        if(this.props.viewPort === 'mobile' || this.state.bioActive === true) {
            return (
                <div className="bio-frame active">

                    <CSSTransition
                    in={transitionActive} timeout={1000}
                    appear={false} enter={true}
                    exit={false} classNames="bio-card-resize">
                        <div className="bio-card active">

                            {this.renderBioContent()}

                        </div>
                    </CSSTransition>
                </div>
            )
        } else {
            return (
                <div className="bio-frame inactive">

                    <CSSTransition
                    in={transitionActive} timeout={1000}
                    appear={false} enter={true}
                    exit={false} classNames="bio-button-resize"
                    onEntered={this.handleTransitionEnd}>
                        <button className="bio-button" onClick={this.handleBioClick}>

                            {this.renderButtonContent()}

                        </button>
                    </CSSTransition>
                </div>
            )
        }
    }
}

export default Bio;