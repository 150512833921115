import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './ShowcaseTile.css';

class ShowcaseTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageLoaded: false,
            firstPath: '',
            firstPathActive: true,
            secondPath: '',
            secondPathActive: false,
            tileState: 0,
            transitionActive: false,
            classConstructors: {
                '0': 'one',
                '1': 'two',
                '2': 'three',
                '3': 'four',
                '4': 'five',
                '5': 'six',
                '6': 'seven',
                '7': 'eight'
            }
        }
    }

    componentDidMount() {
        this.setState({
            firstPath: this.props.targetPath
        });
    }

    componentDidUpdate(prevProps) {
        const { tileState } = this.state;
        if(this.props.targetPath !== prevProps.targetPath) {
            if(tileState === 0) {
                this.setState({
                    secondPath: this.props.targetPath,
                    firstPathActive: false,
                    secondPathActive: true,
                    tileState: 1
                })
            } else {
                this.setState({
                    firstPath: this.props.targetPath,
                    firstPathActive: true,
                    secondPathActive: false,
                    tileState: 0
                })
            }
        }
    }

    handleImageLoad = () => {
        this.setState({ imageLoaded: true });
    }

    render() {
        const { imageLoaded, firstPathActive, secondPathActive, classConstructors } = this.state;
        const rawId = this.props.id;
        const tileClass = classConstructors[rawId];

        return (
        <CSSTransition timeout={1000}
        in={imageLoaded} appear={true}
        enter={true} exit={false}
        classNames="showcase-image-fade">
        <div className={`tile-container ${tileClass}`}>
            <CSSTransition in={firstPathActive} timeout={1000}
            appear={false} enter={true} exit={true}
            classNames="first-tile-rotate">
                <img className="showcase-tile first" onLoad={this.handleImageLoad} alt={`Hebden Showcase Ceramic ${rawId}`} src={this.state.firstPath}></img>
            </CSSTransition>
            <CSSTransition in={secondPathActive} timeout={1000}
            appear={false} enter={true} exit={true}
            classNames="second-tile-rotate">
                <img className="showcase-tile second" alt={`Hebden Showcase Ceramic ${rawId}`} src={this.state.secondPath}></img>
            </CSSTransition>
        </div>
        </CSSTransition>
        )
    }
}

export default ShowcaseTile;