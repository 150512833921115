import React from 'react';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';

import { NotificationContext } from '../../../components/Notifier/Notifier';
import config from '../../../config/config.json';

import './Landing.css';

import ShowcaseTile from './ShowcaseTile/ShowcaseTile';

class Landing extends React.Component {
    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        this.state = {
            imagesLoaded: false,
            tilePaths: [],
            showcaseImages: [],
            iconPath: require("../../../logos/aboutLogo.png"),

            intervalId: '',
            tileIterator: 0,
            storeIterator: 0
        }

        this.renderTiles = this.renderTiles.bind(this);
        this.newShowcaseTile = this.newShowcaseTile.bind(this);
    }

    async componentDidMount() {
        let showcaseImages;

        try {
            // Fetch showcase images from the database and store in state
            const showcaseData = await axios.get(`${config.API}/pages/232`);
            showcaseImages = showcaseData.data.acf["photo_gallery"].gallery[0].map(img => {
                return {path: img["full_image_url"]}
            });
        } catch {
            this.context.dispatchError("Showcase data could not be loaded. Please click here to reload the site.", true);
            return;
        }
        
        this.setState({ showcaseImages }, () => {

            // Get an initial selection
            const { showcaseImages, tilePaths} = this.state; 
            if(showcaseImages.length === 0) {
                return;
            } else {
                let initialSelection = [];
                let i = 0;
                while(initialSelection.length < 8) {

                    if(showcaseImages.length <= 8) {
                    // Less than 8 showcase images returned
                        initialSelection.push(showcaseImages[i]);
                        if(i === showcaseImages.length-1) {
                            i = 0;
                        } else {
                            i += 1;
                        }

                    } else {
                    //More than 8 showcase images returned
                        initialSelection.push(showcaseImages[i]);
                        i += 1;
                    }
                }

                // Map the selection to the tilepaths, signal to render, and start interval
                initialSelection.forEach(image => {
                    tilePaths.push(image.path);
                })

                if(showcaseImages.length > 8) {
                    const intervalId = setInterval(this.newShowcaseTile, 5000);
                    
                    this.setState({imagesLoaded: true, tilePaths, intervalId});
                } else {
                    this.setState({imagesLoaded: true, tilePaths});
                }
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    newShowcaseTile() {
        const { tilePaths, showcaseImages, tileIterator, storeIterator} = this.state;
        let unusedImages = showcaseImages.filter(image => {
            return !tilePaths.some(tilePath => tilePath === image.path);
        });
        
        const nextTilePath = unusedImages[storeIterator].path;
        tilePaths.splice(tileIterator, 1, nextTilePath);

        // Advance the tile and unused image indexes
        if(tileIterator === tilePaths.length-1) {
            this.setState({tileIterator: 0})
        } else {
            this.setState({tileIterator: this.state.tileIterator+1})
        }

        if(storeIterator === unusedImages.length-1) {
            this.setState({storeIterator: 0})
        } else {
            this.setState({storeIterator: this.state.storeIterator+1})
        }

        this.setState({
            tilePaths
        });
    }

    renderTiles() {
        const { tilePaths, imagesLoaded } = this.state;
        if(imagesLoaded) {
            return tilePaths.map((tilePath, i) => {
                return <ShowcaseTile key={i} id={i} targetPath={tilePath} />
            })
        }
    }

    render() {
        const { contentVisible } = this.props;

        return (
            <div className="landing-container">
                <CSSTransition in={contentVisible} timeout={1000}
                appear={true} enter={true} exit={true}
                classNames="showcase-grid-fade">
                <div className="showcase-grid">
                    <div className="showcase-icon-container">
                        <div className="showcase-icon"style={{backgroundImage: `url(${this.state.iconPath})`}} ></div>
                    </div>
                    {this.renderTiles()}
                </div>
                </CSSTransition>
            </div>
        )
    }
}

export default Landing;