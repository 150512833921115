import React from 'react';
import PropTypes from 'prop-types';

const LoadTrigger = (props) => {
    const {
        options,
        onTrigger,
        renderLoading,
        renderWaiting,
        complete,
        ...rest
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);
    
    const loadingRef = React.useRef(false);
    const triggerRef = React.useRef();

    React.useEffect(() => {
        if (complete) return;

        let valid = true;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                (async () => {
                    if (!entry.isIntersecting || loadingRef.current) return;

                    loadingRef.current = true;
                    setIsLoading(true);

                    await onTrigger();

                    if (!valid) return;

                    loadingRef.current = false;
                    setIsLoading(false);
                })();
            })
        }, options || {});

        observer.observe(triggerRef.current);

        return () => {
            valid = false;
            observer.disconnect();
        }
    }, [options, onTrigger, complete]);

    return complete ? null : (
        <div ref={triggerRef} {...rest}>
            {isLoading ? renderLoading() : renderWaiting()}
        </div>
    )
}

LoadTrigger.propTypes = {
    options: PropTypes.object,
    onTrigger: PropTypes.func,
    renderLoading: PropTypes.func,
    renderWaiting: PropTypes.func,
    complete: PropTypes.bool
}

export default LoadTrigger;