import React from 'react';
import PropTypes from 'prop-types';

const SquaredContainer = ({dimension = 'width', children, ...rest}) => {
    const containerRef = React.useRef();

    React.useLayoutEffect(() => {
        const initialStyle = window.getComputedStyle(containerRef.current);
        const [source, shadow] = {width: ['width', 'height'], height: ['height', 'width']}[dimension];

        containerRef.current.style[shadow] = initialStyle[source];

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;

                if (width === height) return;

                entry.target.style[shadow] = entry.contentRect[source] + 'px';
            }
        });

        observer.observe(containerRef.current);

        return () => observer.disconnect();
    }, [dimension]);

    return (
        <div ref={containerRef} {...rest}>
            {children}
        </div>
    )
}

SquaredContainer.propTypes = {
    dimension: PropTypes.oneOf(['height', 'width', undefined])
}

export default SquaredContainer;