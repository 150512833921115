import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Notifier.css';

import config from '../../config/config.json';

export const NotificationContext = React.createContext(null);

const defaultOptions = {
    position: toast.POSITION.BOTTOM_RIGHT
}

const Notifier = ({children}) => {

    const markNotificationRead = React.useCallback((id) => {
        const readNotifications = JSON.parse(localStorage.getItem("readNotifications")) || [];
        localStorage.setItem("readNotifications", JSON.stringify([...readNotifications, id]));
    }, []);

    const refreshAndReload = React.useCallback(() => {
        if (caches) {
            caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
            });
        }
        window.location.reload(true);
    }, []);

    const dispatchAdmin = React.useCallback((id, msg) => {
        toast(msg, {
            autoClose: 10000,
            onClick: () => markNotificationRead(id),
            ...defaultOptions
        });
    }, [markNotificationRead]);

    const dispatchError = React.useCallback((msg, reload = false) => {
        toast.error(msg, {
            autoClose: reload ? false : 10000,
            onClick: () => reload && refreshAndReload(),
            ...defaultOptions
        })
    }, [refreshAndReload]);

    React.useEffect(() => {
        (async () => {
            try {
                const adminNotifications = await axios.get(`${config.API}/notifications`);
                const readNotifications = JSON.parse(localStorage.getItem("readNotifications"));
                
                adminNotifications.data.forEach(({id, acf: { message }}) => {
                    if (!readNotifications || !readNotifications.includes(id)) {
                        dispatchAdmin(id, message);
                    }
                });
            } catch (e) {
                dispatchError("Notifications could not be loaded.");
            }
        })();
    }, [dispatchAdmin, dispatchError]);

    const value = React.useMemo(() => ({
        dispatchError
    }), [dispatchError]);

    return (
        <>
            <NotificationContext.Provider value={value}>
                {children}
            </NotificationContext.Provider>
            <ToastContainer />
        </>
    )
}

export const useNotifications = () => React.useContext(NotificationContext);

export default Notifier;