import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './Event.css';

class Event extends React.Component {

    formatDate = (date) => {
        let year = date.slice(0, 4);
        let month = date.slice(4, 6);
        let day = date.slice(6, 8);
        return `${day}/${month}/${year}`;
    }

    render() {
        const { title, address, date, time, description, gallery } = this.props.event;
        const { start_date, end_date } = date;
        const { start_time, end_time } = time;

        return (
            <CSSTransition
            in={true} timeout={1000}
            appear={true} enter={false} 
            exit={false} classNames="event-card-fade">
                <div className="event-card">
                    <div className="event-detail-container">
                        <h2 className="event-name">{title}</h2>
                        {(start_time || start_date) && (
                            <p className="event-date-time">
                                {start_time && <span>{start_time}&nbsp;</span>}
                                {end_time && <span>-&nbsp;{end_time}&nbsp;</span>}
                                {start_date && start_time && <strong>{'//'}&nbsp;</strong>}
                                {start_date && <span>{this.formatDate(start_date)}&nbsp;</span>}
                                {end_date && <span>-&nbsp;{this.formatDate(end_date)}</span>}
                            </p>
                        )}
                        {address['line_1'] && (
                            <div className="event-address">
                                {Object.keys(address).map((line, i) => {
                                    return <p key={i}>{address[line]}</p>
                                })}
                            </div>
                        )}
                        {description && (
                            <div className="event-description">
                                {description.split('\n\r').map((para, i) => {
                                    return <p key={i}>{para}</p>
                                })}
                            </div>
                        )}
                        {gallery.map((image) => (
                            <div className="event-image" key={image.id}>
                                <img src={image.full_image_url} alt={image.title}  />
                            </div>
                        ))}
                    </div>
                </div>
            </CSSTransition>
        )
    }
}

export default Event;