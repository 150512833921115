import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './Events.css';

import Event from './Event/Event';

class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventsActive: false
        }

        this.handleEventClick = this.handleEventClick.bind(this);
        this.renderButtonContent = this.renderButtonContent.bind(this);
        this.renderTitleContent = this.renderTitleContent.bind(this);
        this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
    }

    componentDidUpdate() {
        const transitionActive = this.props.transitionActive;
        const eventsView = this.props.eventsView;
        const eventsActive = this.state.eventsActive;
        if(!transitionActive && !eventsView && eventsActive) {
            this.setState({
                eventsActive: false
            })
        }
    }

    handleEventClick() {
        const transitionActive = this.props.transitionActive;
        if(!transitionActive) {
            this.props.startTransition();
        }
    }

    handleTransitionEnd() {
        this.props.endTransition();
        this.setState({
            eventsActive: true
        })
    }

    handleContactClick = (e) => {
        e.preventDefault();
        this.props.handleNavClick(e);
    }

    renderButtonContent() {
        const transitionActive = this.props.transitionActive;
        const eventCounter = this.props.events.length === 0 ? '' : `${this.props.events.length} listed`;

        if(this.state.eventsActive === false) {
            return (
                <CSSTransition
                in={transitionActive} timeout={500}
                appear={false} enter={true}
                exit={false} classNames="events-button-fade-out">
                <CSSTransition
                in={true} timeout={500}
                appear={true} enter={false}
                exit={false} classNames="events-button-fade-in">
                    <div className="events-button-items">
                        <h2 className="events-button-title">events</h2>
                        <p className="events-button-sub">{eventCounter}</p>
                        <svg className="forward-vector" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.572 34.288">
                            <g id="double_forward" data-name="double forward" transform="translate(-619 -896)">
                                <path id="forwardArrow" d="M160,99.472,163.461,96l17.111,17.144h0l-17.111,17.144L160,126.816l13.64-13.672Z" transform="translate(459 800)"/>
                                <path id="forwardArrow-2" data-name="forwardArrow" d="M160,99.472,163.461,96l17.111,17.144h0l-17.111,17.144L160,126.816l13.64-13.672Z" transform="translate(480 800)"/>
                            </g>
                        </svg>
                    </div>
                </CSSTransition>
                </CSSTransition>
            )
        }
    }

    renderTitleContent() {
        const transitionActive = this.props.transitionActive;
        if(this.state.eventsActive === true || this.props.viewPort === 'mobile') {
            return (
                <CSSTransition
                in={transitionActive} timeout={200}
                appear={false} enter={true}
                exit={false} classNames="events-title-fade-out">
                <CSSTransition
                in={true} timeout={500}
                appear={true} enter={true}
                exit={false} classNames="events-title-fade-in">
                    <h2 className="events-title">Events</h2>
                </CSSTransition>
                </CSSTransition>
            )
        }
    }

    render() {
        const transitionActive = this.props.transitionActive;
        const eventsActive = this.state.eventsActive;

        if(this.props.viewPort === 'mobile' || eventsActive === true) {
            return (
                <div className="events-frame active">

                    <CSSTransition
                    in={transitionActive} timeout={1000}
                    appear={false} enter={true}
                    exit={false} classNames="spacer-resize">
                        <div className="events-spacer active"></div>
                    </CSSTransition>

                    <CSSTransition
                    in={transitionActive} timeout={1000}
                    appear={false} enter={true}
                    exit={false} classNames="events-title-resize">
                        <div className="events-title-container">

                            {this.renderTitleContent()}
                            
                        </div>
                    </CSSTransition>
                    
                    <CSSTransition
                    in={transitionActive} timeout={500}
                    appear={false} enter={true}
                    exit={false} classNames="events-content-fade">
                        <div className="events-content">
                            {this.props.events.map((event, i) => {
                                return <Event key={i} event={event}/>
                            })}
                            {this.props.events.length === 0 ? 
                            (<CSSTransition
                                in={true} timeout={1000}
                                appear={true} enter={false} 
                                exit={false} classNames="no-events-fade">
                            <div className="no-events">
                                <h4 className="no-events-title">No Events Planned</h4>
                                <p className="no-events-sub">Contact me <a value="/contact" href="annhebdenceramics.co.uk/contact" onClick={this.handleContactClick}>here</a> to arrange a viewing of my current work.</p>
                            </div>
                            </CSSTransition>) : null}
                        </div>
                    </CSSTransition>
                </div>
            )
        } else {
            return (
                <div className="events-frame inactive">

                    <CSSTransition
                    in={transitionActive} timeout={1000}
                    appear={false} enter={true}
                    exit={false} classNames="spacer-resize"
                    onEntered={this.handleTransitionEnd}>
                        <div className="events-spacer inactive"></div>
                    </CSSTransition>

                    <CSSTransition
                    in={transitionActive} timeout={1000}
                    appear={false} enter={true}
                    exit={false} classNames="events-button-resize">
                        <button className="events-button" onClick={this.handleEventClick}>
                            
                            {this.renderButtonContent()}

                        </button>
                    </CSSTransition>
                </div>
            )
        }
    }
}

export default Events;
